import dayjs from 'dayjs'
import Section from '@audioeye-marketing/ui/src/components/Section'
import Container from '@audioeye-marketing/ui/src/components/Container'
import Grid from '@audioeye-marketing/ui/src/components/Grid'
import Stack from '@audioeye-marketing/ui/src/components/Stack'
import Divider from '@audioeye-marketing/ui/src/components/Divider'
import Link from '@audioeye-marketing/ui/src/components/Link'
import Typography from '@audioeye-marketing/ui/src/components/Typography'
import Chip from '@audioeye-marketing/ui/src/components/Chip'
import colors from '@audioeye-marketing/ui/src/theme/colors'
import ImageIndentLeft from '@audioeye/ui-icons/Line/Editor/ImageIndentLeft'
import Image from '@/components/storyblok/items/Image'
import translateSectionStyles from '@/lib/translate-section-styles'

const PostHero = ({ author, publishedDate, updatedDate, title, summary, image }) => {
  const sectionStyles = translateSectionStyles({ background_color: { value: colors.neutral[50] } })

  return (
    <Section {...sectionStyles}>
      <Container constraint="lg">
        <Grid direction="row" container spacing={{ xs: 5, md: 10 }}>
          <Grid xs={12} md={6}>
            <Stack justifyContent="center" sx={{ height: '100%' }}>
              <Stack spacing={1} sx={{ mb: 4 }}>
                <Stack direction="row" spacing={1}>
                  {/* TODO: need the icon for article tag/category */}
                  <Chip variant="plain" color="neutral" startDecorator={<ImageIndentLeft />}>
                    Article
                  </Chip>
                  {/* TODO: what possible tags would go here (need to also pull from storyblok) */}
                  <Chip variant="soft" color="info">
                    Community
                  </Chip>
                  <Chip variant="soft" color="info">
                    Category
                  </Chip>
                </Stack>
                <Typography level="h1" displayMedium sx={{ mb: 1 }}>
                  {title}
                </Typography>
                <Typography>{summary}</Typography>
              </Stack>
              <Stack spacing={1}>
                <Stack direction="row" spacing={1}>
                  <Typography level="body-sm">
                    Author:{' '}
                    <strong>
                      <Link href={`/${author?.full_slug}/`}>{author?.content?.name}</Link>
                    </strong>{' '}
                    {author?.content?.title}
                  </Typography>
                </Stack>
                <Stack spacing={1} direction={{ xs: 'column', sm: 'row' }}>
                  <Typography level="body-sm">
                    Originally Published: {dayjs(publishedDate, 'YYYY-MM-DD HH:mm').format('MM/DD/YYYY')}
                  </Typography>
                  <Divider orientation="vertical" sx={{ display: { xs: 'none', sm: 'block' } }} />
                  <Typography level="body-sm">Last Updated: {dayjs(updatedDate).format('MM/DD/YYYY')}</Typography>
                </Stack>
              </Stack>
            </Stack>
          </Grid>
          <Grid xs={12} md={6}>
            <Stack sx={{ height: '100%' }} alignItems={{ xs: 'flex-start', sm: 'center' }} justifyContent="center">
              <Image blok={{ image }} imagePriority showAltText />
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </Section>
  )
}

export default PostHero
