import Image from 'next/image'
import NextLink from 'next/link'
import { StoryblokServerComponent, storyblokEditable } from '@storyblok/react/rsc'
import Stack from '@audioeye-marketing/ui/src/components/Stack'
import Box from '@audioeye-marketing/ui/src/components/Box'
import Button from '@audioeye-marketing/ui/src/components/Button'
import Drawer from '@audioeye-marketing/ui/src/components/Drawer'
import Accordion from '@audioeye-marketing/ui/src/components/Accordion'
import AccordionGroup from '@audioeye-marketing/ui/src/components/AccordionGroup'
import AccordionSummary from '@audioeye-marketing/ui/src/components/AccordionSummary'
import AccordionDetails from '@audioeye-marketing/ui/src/components/AccordionDetails'
import List from '@audioeye-marketing/ui/src/components/List'
import ListItem from '@audioeye-marketing/ui/src/components/ListItem'
import ListItemButton from '@audioeye-marketing/ui/src/components/ListItemButton'
import Divider from '@audioeye-marketing/ui/src/components/Divider'
import Typography from '@audioeye-marketing/ui/src/components/Typography'
import colors from '@audioeye-marketing/ui/src/theme/colors'
import Menu01 from '@audioeye/ui-icons/Line/General/Menu01'
import determineStoryblokLink from '@/lib/determine-storyblok-link'
import AudioEyeLogo from '@/assets/logos/audioeye-logo-black-purple-symbol.svg'
import AudioEyeSymbolLogo from '@/assets/logos/audioeye-symbol-only-purple.svg'

const Header = ({ blok, rels, customLogoLink }) => {
  const logoLink = blok?.logo_link[0]
  const FinalDesktopLogo = (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Image priority src={AudioEyeLogo} width={151} height={36} alt="audio eye logo" />
    </Box>
  )

  const FinalMobileLogo = (
    <Box>
      <Image
        src={AudioEyeSymbolLogo}
        width={32}
        height={32}
        alt="audio eye logo"
        style={{
          position: 'absolute',
          zIndex: '1',
          top: 'var(--ModalClose-inset, 1rem)',
          left: '1rem',
        }}
      />
    </Box>
  )

  return (
    <Box {...storyblokEditable(blok)}>
      {/* Utility Bar */}
      {blok?.utility_bar?.map((utilityBar) => {
        return <StoryblokServerComponent key={utilityBar?._uid} blok={utilityBar} rels={rels} />
      })}
      {/* Header */}
      <Box
        component="header"
        sx={{
          padding: { xs: '1.25rem 1.5rem', lg: '1.25rem 4rem' },
          borderTop: '1px solid var(--joy-palette-divider)',
          borderBottom: '1px solid var(--joy-palette-divider)',
          '.blok-icon svg': {
            stroke: colors.neutral[600],
          },
          '.blok-icon path': {
            stroke: colors.neutral[600],
          },
        }}
      >
        <Stack direction="row" spacing={{ xs: 2, sm: 8 }} justifyContent="space-between">
          <Stack direction="row" alignItems="center" justifyContent="center" spacing={{ xs: 4, lg: 4 }}>
            {/* Header Logo */}
            {customLogoLink ? (
              <NextLink href={customLogoLink}>{FinalDesktopLogo}</NextLink>
            ) : logoLink ? (
              <StoryblokServerComponent blok={logoLink} trigger={FinalDesktopLogo} />
            ) : (
              FinalDesktopLogo
            )}

            {/* Desktop Header Navigation */}
            <Stack
              component="nav"
              role="navigation"
              direction="row"
              spacing={3}
              sx={{ display: { xs: 'none', lg: 'block' } }}
            >
              {blok?.navigation_items?.map((navigationItem, index) => {
                return (
                  <StoryblokServerComponent
                    key={navigationItem?._uid}
                    blok={navigationItem}
                    color="neutral"
                    navItemNumber={index + 1}
                    rels={rels}
                    size="md"
                  />
                )
              })}
            </Stack>
          </Stack>

          {(blok?.utility_bar?.[0]?.items?.length > 0 || blok?.navigation_items?.length > 0) && (
            // Mobile Header Navigation
            <Box sx={{ display: { xs: 'block', lg: 'none' } }}>
              <Drawer
                trigger={
                  <Button
                    color="neutral"
                    size="md"
                    variant="plain"
                    endDecorator={<Menu01 />}
                    sx={{ position: 'relative', left: '16px' }}
                  >
                    Menu
                  </Button>
                }
                anchor="right"
                // z-index set to be above audioeye vtk
                sx={{ '--Drawer-horizontalSize': { xs: '100%', md: '50%' }, zIndex: '9999999999' }}
              >
                {/* Mobile Logo */}
                {logoLink ? <StoryblokServerComponent blok={logoLink} trigger={FinalMobileLogo} /> : FinalMobileLogo}

                <Box sx={{ padding: '64px 0 0 0', backgroundColor: colors.common.white, height: '100%' }}>
                  <Stack
                    component="nav"
                    role="navigation"
                    direction="column"
                    justifyContent="space-between"
                    sx={{ height: '100%' }}
                  >
                    <Box>
                      {blok?.navigation_items?.map((navigationItem) => {
                        if (navigationItem?.component === 'Mega Menu') {
                          return (
                            <AccordionGroup key={navigationItem?._uid} size="lg">
                              <Accordion>
                                <AccordionSummary>{navigationItem?.button_text}</AccordionSummary>
                                <AccordionDetails sx={{ backgroundColor: colors.neutral[50] }}>
                                  <Stack spacing={2} direction="column" sx={{ height: '100%', pl: 0.5, pt: 2 }}>
                                    {navigationItem?.rows?.map((navigationRow, rowIndex) => {
                                      return navigationRow?.columns?.map((navigationColumn, columnIndex) => {
                                        return (
                                          <Stack key={navigationColumn?._uid}>
                                            {navigationColumn?.list_groups.map((currentListGroup) => {
                                              return (
                                                <StoryblokServerComponent
                                                  blok={currentListGroup}
                                                  key={currentListGroup?._uid}
                                                  collapsePadding
                                                  removeListItemTargetBlankIcon
                                                  backgroundColor={colors.common.white}
                                                />
                                              )
                                            })}
                                            {navigationColumn?.cta?.map((currentCta) => {
                                              return (
                                                <Box key={currentCta?._uid} sx={{ mt: 2 }}>
                                                  <StoryblokServerComponent
                                                    blok={currentCta}
                                                    rels={rels}
                                                    color="neutral"
                                                    size="sm"
                                                    arrowDecorator
                                                    backgroundColor={colors.common.white}
                                                  />
                                                </Box>
                                              )
                                            })}
                                            {(rowIndex + 1) * (columnIndex + 1) <
                                              navigationItem?.rows?.length * navigationRow?.columns?.length && (
                                              <Divider sx={{ mt: 2 }} />
                                            )}
                                          </Stack>
                                        )
                                      })
                                    })}
                                  </Stack>
                                </AccordionDetails>
                              </Accordion>
                            </AccordionGroup>
                          )
                        } else if (navigationItem?.component === 'Button') {
                          const trigger = navigationItem?.trigger?.[0].trigger?.[0]
                          const href = determineStoryblokLink({ blok: trigger })
                          const target = trigger?.link?.target

                          return (
                            <List key={navigationItem?._uid} sx={{ padding: 0 }} size="lg">
                              <ListItem>
                                <ListItemButton
                                  component={NextLink}
                                  href={href}
                                  target={target}
                                  sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}
                                >
                                  <Typography level="title-lg">{navigationItem?.text}</Typography>
                                </ListItemButton>
                              </ListItem>
                            </List>
                          )
                        }

                        return null
                      })}
                      {blok?.utility_bar?.[0]?.items?.map((utilityBarItem) => {
                        const trigger = utilityBarItem?.trigger?.[0]?.trigger?.[0]

                        if (trigger?.component === 'Menu') {
                          return (
                            <AccordionGroup key={utilityBarItem?._uid} size="lg">
                              <Accordion>
                                <AccordionSummary>{utilityBarItem?.text}</AccordionSummary>
                                <AccordionDetails sx={{ backgroundColor: colors.neutral[50] }}>
                                  <Stack direction="column" sx={{ height: '100%', pl: 0.5, pt: 2 }}>
                                    {trigger?.items?.map((menuItem) => {
                                      const menuItemTrigger = menuItem?.trigger?.[0]?.trigger?.[0]
                                      const href = determineStoryblokLink({ blok: menuItemTrigger })
                                      const target = trigger?.link?.target

                                      return (
                                        <List key={menuItem?._uid} sx={{ padding: 0 }}>
                                          <ListItem>
                                            <ListItemButton
                                              component={NextLink}
                                              href={href}
                                              target={target}
                                              sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'flex-start',
                                              }}
                                            >
                                              <Typography level="body-md">{menuItem?.text}</Typography>
                                            </ListItemButton>
                                          </ListItem>
                                        </List>
                                      )
                                    })}
                                  </Stack>
                                </AccordionDetails>
                              </Accordion>
                            </AccordionGroup>
                          )
                        }

                        return null
                      })}
                    </Box>
                    <Box sx={{ backgroundColor: colors.neutral[100] }}>
                      <Divider sx={{ mb: 1.5 }} />
                      {blok?.utility_bar?.[0]?.items?.map((utilityBarItem) => {
                        const trigger = utilityBarItem?.trigger?.[0]?.trigger?.[0]
                        const href = determineStoryblokLink({ blok: trigger })
                        const target = trigger?.link?.target

                        if (trigger?.component === 'Link') {
                          return (
                            <List key={utilityBarItem?._uid} sx={{ padding: 0 }}>
                              <ListItem>
                                <ListItemButton
                                  component={NextLink}
                                  href={href}
                                  target={target}
                                  sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}
                                >
                                  <Typography level="title-md">{utilityBarItem?.text}</Typography>
                                </ListItemButton>
                              </ListItem>
                            </List>
                          )
                        }

                        return null
                      })}

                      <Box sx={{ padding: 1.5 }}>
                        {blok?.cta?.map((cta) => {
                          return (
                            <StoryblokServerComponent key={cta?._uid} blok={cta} rels={rels} size="md" forceFullWidth />
                          )
                        })}
                      </Box>
                    </Box>
                  </Stack>
                </Box>
              </Drawer>
            </Box>
          )}

          {/* Header CTAs */}
          <Box sx={{ display: { xs: 'none', lg: 'flex' }, alignItems: 'center' }}>
            {blok?.cta?.map((cta) => {
              return <StoryblokServerComponent key={cta?._uid} blok={cta} rels={rels} size="md" />
            })}
          </Box>
        </Stack>
      </Box>
    </Box>
  )
}

export default Header
