import {
  render,
  NODE_HEADING,
  NODE_PARAGRAPH,
  MARK_LINK,
  NODE_LI,
  NODE_UL,
  NODE_OL,
} from 'storyblok-rich-text-react-renderer'
import Typography from '@audioeye-marketing/ui/src/components/Typography'
import Link from '@audioeye-marketing/ui/src/components/Link'
import List from '@audioeye-marketing/ui/src/components/List'
import ListItem from '@audioeye-marketing/ui/src/components/ListItem'
import ListItemContent from '@audioeye-marketing/ui/src/components/ListItemContent'
import ListItemDecorator from '@audioeye-marketing/ui/src/components/ListItemDecorator'
import getTextFromChildren from '@audioeye-marketing/ui/src/lib/get-text-from-children'
import Check from '@audioeye/ui-icons/Line/General/Check'
import Plus from '@audioeye/ui-icons/Line/General/Plus'
import Star05 from '@audioeye/ui-icons/Line/Shapes/Star05'
import CodeBlock from '@/components/storyblok/items/CodeBlock'
import Image from '@/components/storyblok/items/Image'
import Video from '@/components/storyblok/items/Video'
import determineLinkColor from '@/lib/determine-link-color'
import generateIdFromText from '@/lib/generate-id-from-text'

const renderRichText = ({ document, options, backgroundColor }) => {
  const color = determineLinkColor({ backgroundColor })
  const checkmarkIcon =
    options?.checkmark === 'check' ? (
      <Check />
    ) : options?.checkmark === 'plus' ? (
      <Plus />
    ) : options?.checkmark === 'star' ? (
      <Star05 />
    ) : null

  const setListMarkers = (node, level = 1) => {
    const bulletMarkers = ['disc', 'circle', 'square'] // For bullet lists
    const orderedMarkers = ['decimal', 'lower-latin', 'lower-roman'] // For ordered lists

    if (node.type === 'bullet_list' || node.type === 'ordered_list') {
      node.attrs = node.attrs || {}
      const markers = node.type === 'bullet_list' ? bulletMarkers : orderedMarkers
      node.attrs.marker = markers[(level - 1) % markers.length]
    }

    if (node.content && Array.isArray(node.content)) {
      for (const child of node.content) {
        setListMarkers(child, node.type === 'list_item' ? level : level + 1)
      }
    }
  }

  const newDocument = document

  newDocument.content = document.content.map((currentContent) => {
    setListMarkers(currentContent)
    return currentContent
  })

  return render(newDocument, {
    markResolvers: {
      [MARK_LINK]: (children, { linktype, href, target }) => {
        return (
          <Link
            href={linktype === 'email' ? `mailto:${href}` : href}
            target={target}
            color={color}
            {...options?.MARK_LINK}
          >
            {children}
          </Link>
        )
      },
    },
    nodeResolvers: {
      [NODE_HEADING]: (children, { level }) => {
        return (
          <Typography
            level={`h${level}`}
            id={level === 2 ? generateIdFromText(getTextFromChildren(children)) : null}
            {...options?.NODE_HEADING}
          >
            {children}
          </Typography>
        )
      },
      [NODE_PARAGRAPH]: (children) => {
        return (
          <Typography level="body-lg" {...options?.NODE_PARAGRAPH}>
            {children}
          </Typography>
        )
      },
      [NODE_OL]: (children, attrs) => {
        const marker = attrs?.marker

        return (
          <List component="ol" marker={!checkmarkIcon ? marker : null} sx={{ textAlign: 'left' }} {...options?.NODE_OL}>
            {children}
          </List>
        )
      },
      [NODE_UL]: (children, attrs) => {
        const marker = attrs?.marker

        return (
          <List component="ul" marker={!checkmarkIcon ? marker : null} sx={{ textAlign: 'left' }} {...options?.NODE_UL}>
            {children}
          </List>
        )
      },
      [NODE_LI]: (children) => {
        return (
          <ListItem>
            {Boolean(checkmarkIcon) && <ListItemDecorator>{checkmarkIcon}</ListItemDecorator>}
            <ListItemContent>{children}</ListItemContent>
          </ListItem>
        )
      },
    },
    blokResolvers: {
      'Code Block': (props) => {
        return <CodeBlock blok={props} />
      },
      Image: (props) => {
        return <Image blok={props} maxWidth={options?.maxWidth} showAltText={options?.showAltText} />
      },
      Video: (props) => {
        return <Video blok={props} />
      },
    },
  })
}

export default renderRichText
