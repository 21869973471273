import { StoryblokServerComponent, storyblokEditable } from '@storyblok/react/rsc'
import SkipLink from '@audioeye-marketing/ui/src/components/SkipLink'
import Stack from '@audioeye-marketing/ui/src/components/Stack'
import Box from '@audioeye-marketing/ui/src/components/Box'
import LinkButton from '@audioeye-marketing/ui/src/components/LinkButton'
import ArrowLeft from '@audioeye/ui-icons/Line/Arrows/ArrowLeft'
import Frame from '@/components/storyblok/global/Frame'
import PostHero from '@/components/PostHero'
import PostFooter from '@/components/PostFooter'
import TableOfContents from '@/components/TableOfContents'
import PasswordProtectedPage from '@/components/PasswordProtectedPage'

const Post = async ({ blok, rels, storyId, authenticationCookie, updatedAt }) => {
  const numberOfBeforeContentSections = blok?.frame?.content?.before_content?.length

  if (
    blok?.password &&
    authenticationCookie?.value !== 'true' &&
    process.env.NEXT_PUBLIC_WWW_APP_ENV === 'production'
  ) {
    return <PasswordProtectedPage cmsPassword={blok?.password} storyId={storyId} />
  }

  return (
    <div {...storyblokEditable(blok)}>
      <SkipLink />
      <Frame blok={blok?.frame?.content} rels={rels}>
        <PostHero
          author={blok.author}
          publishedDate={blok.published_date}
          updatedDate={updatedAt}
          title={blok.title}
          summary={blok.summary}
          image={blok.social_banner}
        />
        <Stack direction={{ xs: 'column', lg: 'row' }} justifyContent="center">
          <TableOfContents
            pageData={blok}
            backButton={
              <LinkButton href="/blog/" size="sm" variant="plain" startDecorator={<ArrowLeft />} sx={{ mb: 3 }}>
                Back to all posts
              </LinkButton>
            }
          />
          {/* 754 is calculated by the max width of rich text + 40 padding on both sides for our md padding section */}
          <Box sx={{ width: '100%', maxWidth: '754px', margin: { xs: '0 auto', lg: 'inherit' } }}>
            {blok?.body?.map((currentSection, index) => (
              <StoryblokServerComponent
                blok={currentSection}
                key={currentSection?._uid}
                rels={rels}
                // * This will never be anything but 2 since we have the PostHero
                headingLevel={2}
                // * Only the first section could have priority if no before content is in the frame
                imagePriority={numberOfBeforeContentSections === 0 && index === 1}
                padding="md"
              />
            ))}
          </Box>
        </Stack>
        <PostFooter />
      </Frame>
    </div>
  )
}

export default Post
