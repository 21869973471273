import { StoryblokServerComponent, storyblokEditable } from '@storyblok/react/rsc'
import UIListItem from '@audioeye-marketing/ui/src/components/ListItem'
import ListItemDecorator from '@audioeye-marketing/ui/src/components/ListItemDecorator'
import ListItemButton from '@audioeye-marketing/ui/src/components/ListItemButton'
import Typography from '@audioeye-marketing/ui/src/components/Typography'
import Stack from '@audioeye-marketing/ui/src/components/Stack'
import Share03 from '@audioeye/ui-icons/Line/General/Share03'
import determineListItemButtonColor from '@/lib/determine-list-item-button-color'

const ListItem = ({ blok, backgroundColor, size, removeListItemTargetBlankIcon }) => {
  const color = determineListItemButtonColor({ backgroundColor })
  const triggerBlok = blok?.trigger?.[0]
  const trigger = triggerBlok?.trigger?.[0]
  const decorator = removeListItemTargetBlankIcon ? null : trigger?.link?.target === '_blank' ? <Share03 /> : null
  const hasIcon = blok?.icon?.[0]
  const ListItem = (
    <ListItemButton color={color}>
      <Stack spacing={1} direction="column" alignItems="flex-start">
        <Stack spacing={2} direction="row" alignItems="center" justifyContent="center">
          {Boolean(hasIcon) && (
            <StoryblokServerComponent blok={blok?.icon?.[0]} height={20} width={20} strokeWidth={2} />
          )}
          <Typography level={size === 'sm' ? 'body-sm' : 'body-md'}>{blok?.text}</Typography>
        </Stack>
        {Boolean(blok?.sub_text) && (
          <Typography level="body-xs" sx={{ display: { xs: 'none', lg: 'block' } }}>
            {blok?.sub_text}
          </Typography>
        )}
      </Stack>
      {Boolean(decorator) && (
        <ListItemDecorator sx={{ alignSelf: 'center', paddingTop: '0' }}>{decorator}</ListItemDecorator>
      )}
    </ListItemButton>
  )

  return (
    <UIListItem {...storyblokEditable(blok)} sx={{ padding: '0' }}>
      {triggerBlok ? <StoryblokServerComponent blok={triggerBlok} triggerComponent={ListItem} /> : ListItem}
    </UIListItem>
  )
}

export default ListItem
