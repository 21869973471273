const Youtube = () => (
  <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M22.3332 8.875H9.47146C6.94801 8.875 4.90234 10.9207 4.90234 13.4441V19.8736C4.90234 22.3971 6.94801 24.4428 9.47146 24.4428H22.3332C24.8567 24.4428 26.9023 22.3971 26.9023 19.8736V13.4441C26.9023 10.9207 24.8567 8.875 22.3332 8.875ZM19.2432 16.9717L13.2273 19.8409C13.067 19.9174 12.8818 19.8005 12.8818 19.6229V13.7052C12.8818 13.5251 13.0719 13.4083 13.2325 13.4897L19.2484 16.5383C19.4272 16.6289 19.4241 16.8854 19.2432 16.9717Z"
      fill="currentColor"
    />
  </svg>
)

export default Youtube
