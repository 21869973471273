import { StoryblokServerComponent, storyblokEditable } from '@storyblok/react/rsc'
import UITable from '@audioeye-marketing/ui/src/components/Table'
import Stack from '@audioeye-marketing/ui/src/components/Stack'
import Box from '@audioeye-marketing/ui/src/components/Box'

const Table = ({ blok, backgroundColor }) => {
  return (
    <Stack {...storyblokEditable(blok)} spacing={4}>
      {/* sticky header not working because of this box */}
      <Box sx={{ maxWidth: '100%', overflowX: 'auto' }}>
        <UITable
          stickyHeader
          variant="plain"
          borderAxis="both"
          className="blok-table"
          sx={{
            // To match rich text content width
            minWidth: '674px',
            '& thead th': { backgroundColor },
          }}
        >
          <thead>
            {blok?.header_row?.map((currentHeaderRow) => {
              return (
                <StoryblokServerComponent
                  key={currentHeaderRow?._uid}
                  blok={currentHeaderRow}
                  backgroundColor={backgroundColor}
                />
              )
            })}
          </thead>

          <tbody>
            {blok?.body_rows?.map((currentBodyRow) => {
              return (
                <StoryblokServerComponent
                  key={currentBodyRow?._uid}
                  blok={currentBodyRow}
                  backgroundColor={backgroundColor}
                />
              )
            })}
          </tbody>
        </UITable>
      </Box>
      {blok?.legal?.map((currentRichText) => (
        <StoryblokServerComponent
          key={currentRichText?._uid}
          blok={currentRichText}
          backgroundColor={backgroundColor}
        />
      ))}
    </Stack>
  )
}

export default Table
