import { StoryblokServerComponent, storyblokEditable } from '@storyblok/react/rsc'
import SkipLink from '@audioeye-marketing/ui/src/components/SkipLink'
import Stack from '@audioeye-marketing/ui/src/components/Stack'
import Box from '@audioeye-marketing/ui/src/components/Box'
import Frame from '@/components/storyblok/global/Frame'
import TableOfContents from '@/components/TableOfContents'
import PasswordProtectedPage from '@/components/PasswordProtectedPage'

const Guide = async ({ blok, rels, storyId, authenticationCookie }) => {
  const numberOfBeforeContentSections = blok?.frame?.content?.before_content?.length
  const hasBeforeContent = numberOfBeforeContentSections > 0
  const numberOfHeroSections = blok?.hero?.length

  if (
    blok?.password &&
    authenticationCookie?.value !== 'true' &&
    process.env.NEXT_PUBLIC_WWW_APP_ENV === 'production'
  ) {
    return <PasswordProtectedPage cmsPassword={blok?.password} storyId={storyId} />
  }

  return (
    <div {...storyblokEditable(blok)}>
      <SkipLink />
      <Frame blok={blok?.frame?.content} rels={rels}>
        {blok?.hero?.map((currentSection, index) => (
          <StoryblokServerComponent
            blok={currentSection}
            key={currentSection?._uid}
            rels={rels}
            headingLevel={index === 0 && !hasBeforeContent ? 1 : 2}
            imagePriority={numberOfBeforeContentSections + index === 0 || numberOfBeforeContentSections + index === 1}
          />
        ))}
        <Stack direction={{ xs: 'column', lg: 'row' }}>
          <TableOfContents
            pageData={blok}
            sx={{
              maxWidth: { xs: '100%', lg: '328px' },
              pl: { xs: 5, lg: 8 },
            }}
          />
          <Box sx={{ width: '100%' }}>
            {blok?.body?.map((currentSection, index) => (
              <StoryblokServerComponent
                blok={currentSection}
                key={currentSection?._uid}
                rels={rels}
                // * This will never be anything but 2 since we have the Hero above required in storyblok to be a min of 1 section
                headingLevel={2}
                // * Only have to check if the body section is the first one since we have the Hero above with at least 1
                imagePriority={numberOfBeforeContentSections + numberOfHeroSections + index === 1}
                padding="md"
              />
            ))}
          </Box>
        </Stack>
      </Frame>
    </div>
  )
}

export default Guide
