import { StoryblokServerComponent, storyblokEditable } from '@storyblok/react/rsc'
import Section from '@audioeye-marketing/ui/src/components/Section'
import Container from '@audioeye-marketing/ui/src/components/Container'
import Grid from '@audioeye-marketing/ui/src/components/Grid'
import Stack from '@audioeye-marketing/ui/src/components/Stack'
import translateSectionStyles from '@/lib/translate-section-styles'
import TexturedImage from '@/components/TexturedImage'

const SplitContent = ({ blok, rels, headingLevel, padding, imagePriority }) => {
  const sectionStyles = translateSectionStyles(blok?.section_styles?.[0])
  const texture = blok?.texture && <TexturedImage position={blok?.reverse_content ? 'left' : 'right'} />

  return (
    <Section {...storyblokEditable(blok)} {...sectionStyles} id={blok?.anchor?.[0]?.anchor_id} padding={padding}>
      <Container constraint="lg">
        <Grid direction={blok?.reverse_content ? 'row-reverse' : 'row'} container spacing={{ xs: 5, md: 10 }}>
          <Grid xs={12} md={6}>
            <Stack
              justifyContent={blok?.media?.[0]?.component === 'Form' ? 'flex-start' : 'center'}
              sx={{ height: '100%' }}
            >
              {blok?.text_content?.map((currentTextContent) => (
                <StoryblokServerComponent
                  key={currentTextContent?._uid}
                  blok={currentTextContent}
                  rels={rels}
                  backgroundColor={sectionStyles?.backgroundColor}
                  headingLevel={headingLevel}
                  justifyCtaContent={{ xs: 'flex-start', sm: 'center', md: 'flex-start' }}
                  alignItems={{ xs: 'flex-start', sm: 'center', md: 'flex-start' }}
                  textAlign={{ xs: 'left', sm: 'center', md: 'left' }}
                />
              ))}
            </Stack>
          </Grid>
          <Grid xs={12} md={6}>
            <Stack
              sx={{ height: '100%' }}
              alignItems={{ xs: 'flex-start', sm: 'center' }}
              justifyContent={blok?.media?.[0]?.component === 'Button Group' ? 'flex-end' : 'center'}
            >
              {blok?.media?.map((currentMedia) => (
                <StoryblokServerComponent
                  key={currentMedia?._uid}
                  blok={currentMedia}
                  rels={rels}
                  imagePriority={imagePriority}
                  backgroundColor={sectionStyles?.backgroundColor}
                  card
                />
              ))}
            </Stack>
          </Grid>
        </Grid>
      </Container>
      {texture}
    </Section>
  )
}

export default SplitContent
