import { storyblokInit, apiPlugin } from '@storyblok/react/rsc'
//* Globals
import Author from '@/components/storyblok/global/Author'
import Footer from '@/components/storyblok/global/Footer'
import Frame from '@/components/storyblok/global/Frame'
import LogoBanner from '@/components/storyblok/global/LogoBanner'
import Guide from '@/components/storyblok/global/Guide'
import Header from '@/components/storyblok/global/Header'
import Page from '@/components/storyblok/global/Page'
import Post from '@/components/storyblok/global/Post'
import Press from '@/components/storyblok/global/Press'
import SecondaryNavigation from '@/components/storyblok/global/SecondaryNavigation'
import Svg from '@/components/storyblok/global/Svg'
// * Items
import AccessibilityStatement from '@/components/storyblok/items/AccessibilityStatement'
import Accordion from '@/components/storyblok/items/Accordion'
import AccordionItem from '@/components/storyblok/items/AccordionItem'
import AccordionTabs from '@/components/storyblok/items/AccordionTabs'
import AccordionTabsItem from '@/components/storyblok/items/AccordionTabsItem'
import Airtable from '@/components/storyblok/items/Airtable'
import Button from '@/components/storyblok/items/Button'
import ButtonGroup from '@/components/storyblok/items/ButtonGroup'
import Card from '@/components/storyblok/items/Card'
import Checkbox from '@/components/storyblok/items/Checkbox'
import Chip from '@/components/storyblok/items/Chip'
import CodeBlock from '@/components/storyblok/items/CodeBlock'
import ColorContrastChecker from '@/components/storyblok/items/ColorContrastChecker'
import FigmaEmbed from '@/components/storyblok/items/FigmaEmbed'
import FeaturedContent from '@/components/storyblok/items/FeaturedContent'
import Form from '@/components/storyblok/items/Form'
import GlobalButton from '@/components/storyblok/items/GlobalButton'
import GlobalPlanCard from '@/components/storyblok/items/GlobalPlanCard'
import GlobalSvg from '@/components/storyblok/items/GlobalSvg'
import Grid from '@/components/storyblok/items/Grid'
import Icon from '@/components/storyblok/items/Icon'
import IconText from '@/components/storyblok/items/IconText'
import Image from '@/components/storyblok/items/Image'
import JobOpenings from '@/components/storyblok/items/JobOpenings'
import Link from '@/components/storyblok/items/Link'
import List from '@/components/storyblok/items/List'
import ListGroup from '@/components/storyblok/items/ListGroup'
import ListItem from '@/components/storyblok/items/ListItem'
import MegaMenu from '@/components/storyblok/items/MegaMenu'
import MegaMenuColumn from '@/components/storyblok/items/MegaMenuColumn'
import MegaMenuRow from '@/components/storyblok/items/MegaMenuRow'
import Menu from '@/components/storyblok/items/Menu'
import MenuItem from '@/components/storyblok/items/MenuItem'
import Modal from '@/components/storyblok/items/Modal'
import PlanCard from '@/components/storyblok/items/PlanCard'
import Quote from '@/components/storyblok/items/Quote'
import RichText from '@/components/storyblok/items/RichText'
import ScannerForm from '@/components/storyblok/items/ScannerForm'
import ScheduleMeeting from '@/components/storyblok/items/ScheduleMeeting'
import Table from '@/components/storyblok/items/Table'
import TableCell from '@/components/storyblok/items/TableCell'
import TableHeaderCell from '@/components/storyblok/items/TableHeaderCell'
import TableHeaderRow from '@/components/storyblok/items/TableHeaderRow'
import TableRow from '@/components/storyblok/items/TableRow'
import TextContent from '@/components/storyblok/items/TextContent'
import TextLink from '@/components/storyblok/items/TextLink'
import Trigger from '@/components/storyblok/items/Trigger'
import UtilityBar from '@/components/storyblok/items/UtilityBar'
import Video from '@/components/storyblok/items/Video'
// * Sections
import AccordionContent from '@/components/storyblok/sections/AccordionContent'
import BasicContent from '@/components/storyblok/sections/BasicContent'
import ColumnedContent from '@/components/storyblok/sections/ColumnedContent'
import GlobalLogoBanner from '@/components/storyblok/sections/GlobalLogoBanner'
import GlobalSecondaryNavigation from '@/components/storyblok/sections/GlobalSecondaryNavigation'
import QuoteContent from '@/components/storyblok/sections/QuoteContent'
import RichTextContent from '@/components/storyblok/sections/RichTextContent'
import SplitContent from '@/components/storyblok/sections/SplitContent'
import StaticContent from '@/components/storyblok/sections/StaticContent'
import TableContent from '@/components/storyblok/sections/TableContent'

const components = {
  //* Globals
  Author,
  Footer,
  Frame,
  'Logo Banner': LogoBanner,
  Guide: Guide,
  Header,
  Page,
  Post,
  Press,
  'Secondary Navigation': SecondaryNavigation,
  Svg,
  // * Items
  'Accessibility Statement': AccessibilityStatement,
  Accordion,
  'Accordion Item': AccordionItem,
  'Accordion Tabs': AccordionTabs,
  'Accordion Tabs Item': AccordionTabsItem,
  Airtable,
  Button,
  'Button Group': ButtonGroup,
  Card,
  Checkbox,
  Chip,
  'Code Block': CodeBlock,
  'Color Contrast Checker': ColorContrastChecker,
  'Figma Embed': FigmaEmbed,
  'Featured Content': FeaturedContent,
  Form,
  'Global - Button': GlobalButton,
  'Global - Plan Card': GlobalPlanCard,
  'Global - Svg': GlobalSvg,
  Grid,
  Icon,
  'Icon Text': IconText,
  Image,
  'Job Openings': JobOpenings,
  Link,
  List,
  'List Group': ListGroup,
  'List Item': ListItem,
  'Mega Menu': MegaMenu,
  'Mega Menu Column': MegaMenuColumn,
  'Mega Menu Row': MegaMenuRow,
  Menu,
  'Menu Item': MenuItem,
  Modal,
  'Plan Card': PlanCard,
  Quote,
  'Rich Text': RichText,
  'Scanner Form': ScannerForm,
  'Schedule Meeting': ScheduleMeeting,
  Table,
  'Table Cell': TableCell,
  'Table Header Cell': TableHeaderCell,
  'Table Header Row': TableHeaderRow,
  'Table Row': TableRow,
  'Text Content': TextContent,
  'Text Link': TextLink,
  Trigger,
  'Utility Bar': UtilityBar,
  Video,
  // * Sections
  'Accordion Content': AccordionContent,
  'Basic Content': BasicContent,
  'Columned Content': ColumnedContent,
  'Global - Logo Banner': GlobalLogoBanner,
  'Global - Secondary Navigation': GlobalSecondaryNavigation,
  'Quote Content': QuoteContent,
  'Rich Text Content': RichTextContent,
  'Split Content': SplitContent,
  'Static Content': StaticContent,
  'Table Content': TableContent,
}

export const getStoryblokApi = storyblokInit({
  accessToken: process.env.NEXT_PUBLIC_WWW_STORYBLOK_API_TOKEN,
  use: [apiPlugin],
  apiOptions: {
    region: 'us',
  },
  components,
})
