import { StoryblokServerComponent, storyblokEditable } from '@storyblok/react/rsc'
import UIMenuItem from '@audioeye-marketing/ui/src/components/MenuItem'
import Stack from '@audioeye-marketing/ui/src/components/Stack'
import Typography from '@audioeye-marketing/ui/src/components/Typography'

const MenuItem = ({ blok }) => {
  const triggerBlok = blok?.trigger?.[0]
  const trigger = triggerBlok?.trigger?.[0]
  const hasIcon = blok?.icon?.[0]

  const FinalMenuItem = (
    <UIMenuItem
      {...storyblokEditable(blok)}
      triggersWhat={trigger?.component}
      sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}
    >
      <Stack spacing={2} direction="row" alignItems="center" justifyContent="center">
        {Boolean(hasIcon) && <StoryblokServerComponent blok={blok?.icon?.[0]} height={20} width={20} strokeWidth={2} />}
        <Typography>{blok?.text}</Typography>
      </Stack>
      {Boolean(blok?.sub_text) && <Typography level="body-xs">{blok?.sub_text}</Typography>}
    </UIMenuItem>
  )

  return triggerBlok ? <StoryblokServerComponent blok={triggerBlok} triggerComponent={FinalMenuItem} /> : FinalMenuItem
}

export default MenuItem
