import { StoryblokServerComponent, storyblokEditable } from '@storyblok/react/rsc'
import Section from '@audioeye-marketing/ui/src/components/Section'
import Container from '@audioeye-marketing/ui/src/components/Container'
import Grid from '@audioeye-marketing/ui/src/components/Grid'
import Stack from '@audioeye-marketing/ui/src/components/Stack'
import Avatar from '@audioeye-marketing/ui/src/components/Avatar'
import Typography from '@audioeye-marketing/ui/src/components/Typography'
import Link from '@audioeye-marketing/ui/src/components/Link'
import colors from '@audioeye-marketing/ui/src/theme/colors'
import translateSectionStyles from '@/lib/translate-section-styles'

const Author = ({ blok, rels, authorPosts }) => {
  const sectionStyles = translateSectionStyles({ background_color: { value: colors.neutral[50] } })

  return (
    <Section {...storyblokEditable(blok)} {...sectionStyles}>
      <Container constraint="lg">
        <Grid container spacing={{ xs: 5, sm: 6 }}>
          <Grid xs={12} sm={8}>
            <Stack spacing={3}>
              <Stack direction="row" spacing={2} alignItems="center">
                <Avatar alt={blok?.name} src={blok?.profile_image?.filename} color="primary" size="lg" variant="soft" />
                <Stack>
                  <Typography level="h4">{blok?.name}</Typography>
                  {Boolean(blok?.title) && <Typography level="body-sm">{blok?.title}</Typography>}
                </Stack>
              </Stack>
              {blok?.description?.map((currentDescription) => (
                <StoryblokServerComponent key={currentDescription?._uid} blok={currentDescription} rels={rels} />
              ))}
            </Stack>
          </Grid>
          <Grid xs={12} sm={4}>
            <Stack
              spacing={3}
              sx={{
                height: '100%',
                position: 'relative',
                ':after': {
                  xs: {
                    content: '""',
                    display: 'block',
                    height: '1px',
                    width: '100%',
                    backgroundColor: 'var(--joy-palette-divider)',
                    top: '-24px',
                    position: 'absolute',
                  },
                  sm: {
                    content: '""',
                    display: 'block',
                    height: '100%',
                    width: '1px',
                    backgroundColor: 'var(--joy-palette-divider)',
                    left: '-24px',
                    top: '0',
                    position: 'absolute',
                  },
                },
              }}
            >
              <Typography level="title-md">Recent Articles</Typography>
              <Stack spacing={2}>
                {(!authorPosts || authorPosts?.length === 0) && (
                  <Typography level="body-md">No articles written by this author at this time.</Typography>
                )}
                {authorPosts?.map((currentArticle) => {
                  return (
                    <Typography key={currentArticle?.id} level="title-md">
                      <Link href={`/${currentArticle?.full_slug}/`}>{currentArticle?.name}</Link>
                    </Typography>
                  )
                })}
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </Section>
  )
}

export default Author
