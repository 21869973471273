import { StoryblokServerComponent, storyblokEditable } from '@storyblok/react/rsc'
import Stack from '@audioeye-marketing/ui/src/components/Stack'
import Typography from '@audioeye-marketing/ui/src/components/Typography'
import Box from '@audioeye-marketing/ui/src/components/Box'
import Container from '@audioeye-marketing/ui/src/components/Container'
import generateIdFromText from '@/lib/generate-id-from-text'

const TextContent = ({ blok, rels, alignItems, justifyCtaContent, headingLevel, backgroundColor, textAlign }) => {
  // TODO: eventually we need a better way of handling alignLeft
  const alignLeft =
    alignItems === 'flex-start' ||
    (typeof alignItems === 'object' &&
      (alignItems?.xs === 'flex-start' ||
        alignItems?.sm === 'flex-start' ||
        alignItems?.md === 'flex-start' ||
        alignItems?.lg === 'flex-start'))

  return (
    <Box
      {...storyblokEditable(blok)}
      sx={{ textAlign: textAlign ? textAlign : { xs: 'left', sm: alignLeft ? 'left' : 'center' } }}
    >
      <Stack spacing={5}>
        <Stack spacing={1}>
          {Boolean(blok?.eyebrow) && (
            <Container constraint={alignLeft ? '100%' : 'sm'}>
              <Typography level="eyebrow" component="p">
                {blok?.eyebrow}
              </Typography>
            </Container>
          )}
          <Stack spacing={2}>
            {Boolean(blok?.heading) && (
              <Container constraint={alignLeft ? '100%' : 'md'}>
                <Typography
                  displayLarge={blok?.large_heading}
                  level={`h${headingLevel}`}
                  id={headingLevel === 2 ? generateIdFromText(blok?.heading) : null}
                >
                  {blok?.heading}
                </Typography>
              </Container>
            )}
            {blok?.text?.map((currentText) => (
              <Container key={blok?._uid} constraint={alignLeft ? '100%' : 'sm'}>
                <StoryblokServerComponent blok={currentText} backgroundColor={backgroundColor} />
              </Container>
            ))}
          </Stack>
        </Stack>

        {blok?.cta?.map((currentCta) => (
          <Box
            key={blok?._uid}
            sx={{
              display: 'flex',
              width: '100%',
              alignItems: 'center',
              justifyContent: justifyCtaContent
                ? justifyCtaContent
                : alignLeft
                  ? 'flex-start'
                  : { xs: 'flex-start', sm: 'center' },
            }}
          >
            <StoryblokServerComponent blok={currentCta} rels={rels} backgroundColor={backgroundColor} />
          </Box>
        ))}
      </Stack>
    </Box>
  )
}

export default TextContent
