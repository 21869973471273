import { StoryblokServerComponent, storyblokEditable } from '@storyblok/react/rsc'
import Section from '@audioeye-marketing/ui/src/components/Section'
import Container from '@audioeye-marketing/ui/src/components/Container'
import Stack from '@audioeye-marketing/ui/src/components/Stack'
import translateSectionStyles from '@/lib/translate-section-styles'

const ColumnedContent = ({ blok, rels, headingLevel, padding, imagePriority }) => {
  const sectionStyles = translateSectionStyles(blok?.section_styles?.[0])
  const align = blok?.left_aligned ? 'flex-start' : 'center'
  const alignLeft = align === 'flex-start'

  return (
    <Section {...storyblokEditable(blok)} {...sectionStyles} id={blok?.anchor?.[0]?.anchor_id} padding={padding}>
      <Container constraint="lg">
        <Stack spacing={{ xs: 3.75, md: 5.5 }}>
          {blok?.text_content?.map((currentTextContent) => (
            <Container key={currentTextContent?._uid} leftAlign={alignLeft} constraint={alignLeft ? 'sm' : 'md'}>
              <StoryblokServerComponent
                blok={currentTextContent}
                rels={rels}
                alignItems={align}
                backgroundColor={sectionStyles?.backgroundColor}
                headingLevel={headingLevel}
              />
            </Container>
          ))}

          {blok?.grid?.map((currentGrid) => (
            <StoryblokServerComponent
              key={currentGrid?._uid}
              blok={currentGrid}
              rels={rels}
              backgroundColor={sectionStyles?.backgroundColor}
              imagePriority={imagePriority}
            />
          ))}
        </Stack>
      </Container>
    </Section>
  )
}

export default ColumnedContent
