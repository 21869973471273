import Image from 'next/image'
import Stack from '@audioeye-marketing/ui/src/components/Stack'
import Box from '@audioeye-marketing/ui/src/components/Box'
import Divider from '@audioeye-marketing/ui/src/components/Divider'
import IconButton from '@audioeye-marketing/ui/src/components/IconButton'
import colors from '@audioeye-marketing/ui/src/theme/colors'
import determineButtonColor from '@/lib/determine-button-color'
import AudioEyeLogoWhiteTealSymbol from '@/assets/logos/audioeye-logo-white-teal-symbol.svg'
import LinkedIn from '@/assets/social-logos/LinkedIn.jsx'
import Instagram from '@/assets/social-logos/Instagram.jsx'
import X from '@/assets/social-logos/X.jsx'
import Facebook from '@/assets/social-logos/Facebook.jsx'
import Youtube from '@/assets/social-logos/Youtube.jsx'

const LogoAndSocialLinks = ({ backgroundColor }) => {
  const color = determineButtonColor({ backgroundColor })

  return (
    <Stack direction={{ xs: 'column', md: 'row' }} justifyContent="space-between" alignItems="center" spacing={3}>
      <Image
        quality={100}
        src={AudioEyeLogoWhiteTealSymbol}
        width={420 / 1.75}
        height={80 / 1.75}
        alt="audio eye logo"
      />
      <Box
        sx={{
          display: { xs: 'none', md: 'flex' },
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <Divider sx={{ width: '100%', backgroundColor: colors.light[800] }} />
      </Box>
      <Stack direction="row" spacing={1}>
        <IconButton
          color={color}
          href="https://www.linkedin.com/company/audioeye-inc/mycompany/"
          aria-label="Visit our LinkedIn page"
          target="_blank"
        >
          <LinkedIn />
        </IconButton>
        <IconButton
          color={color}
          href="https://www.instagram.com/audioeyeinc/"
          aria-label="Visit our Instagram page"
          target="_blank"
        >
          <Instagram />
        </IconButton>
        <IconButton color={color} href="https://x.com/audioeyeinc" aria-label="Visit our X page" target="_blank">
          <X />
        </IconButton>
        <IconButton
          color={color}
          href="https://www.facebook.com/audioeyeinc"
          aria-label="Visit our Facebook page"
          target="_blank"
        >
          <Facebook />
        </IconButton>
        <IconButton
          color={color}
          href="https://www.youtube.com/c/AudioEye"
          aria-label="Visit our Youtube page"
          target="_blank"
        >
          <Youtube />
        </IconButton>
      </Stack>
    </Stack>
  )
}

export default LogoAndSocialLinks
