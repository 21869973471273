import { StoryblokServerComponent, storyblokEditable } from '@storyblok/react/rsc'

const GlobalSecondaryNavigation = ({ blok, rels }) => {
  const content = {
    ...blok?.secondary_navigation?.content,
    dark: blok?.dark,
    anchor: blok?.anchor?.length > 0 ? blok?.anchor : blok?.secondary_navigation?.content?.anchor,
  }

  return <StoryblokServerComponent {...storyblokEditable(blok)} blok={content} rels={rels} />
}

export default GlobalSecondaryNavigation
