import dayjs from 'dayjs'
import Section from '@audioeye-marketing/ui/src/components/Section'
import Container from '@audioeye-marketing/ui/src/components/Container'
import Chip from '@audioeye-marketing/ui/src/components/Chip'
import Box from '@audioeye-marketing/ui/src/components/Box'
import Typography from '@audioeye-marketing/ui/src/components/Typography'
import colors from '@audioeye-marketing/ui/src/theme/colors'
import ImageIndentLeft from '@audioeye/ui-icons/Line/Editor/ImageIndentLeft'
import translateSectionStyles from '@/lib/translate-section-styles'

const PressHero = ({ title, publishedDate }) => {
  const sectionStyles = translateSectionStyles({ background_color: { value: colors.neutral[50] } })

  return (
    <Section {...sectionStyles}>
      <Container constraint="lg">
        <Container constraint="md">
          <Box sx={{ textAlign: { xs: 'left', sm: 'center' } }}>
            <Chip sx={{ mb: 1 }} startDecorator={<ImageIndentLeft />}>
              Press
            </Chip>
            <Typography level="h1" sx={{ mb: 4 }}>
              {title}
            </Typography>
            <Typography level="body-sm">
              Originally Published: {dayjs(publishedDate, 'YYYY-MM-DD HH:mm').format('MM/DD/YYYY')}
            </Typography>
          </Box>
        </Container>
      </Container>
    </Section>
  )
}

export default PressHero
