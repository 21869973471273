import { StoryblokServerComponent, storyblokEditable } from '@storyblok/react/rsc'
import Box from '@audioeye-marketing/ui/src/components/Box'
import colors from '@audioeye-marketing/ui/src/theme/colors'

const SecondaryNavigation = ({ blok, rels }) => {
  const backgroundColor = blok?.dark ? colors.common.black : colors.neutral[50]

  return (
    <Box
      {...storyblokEditable(blok)}
      component="nav"
      sx={{
        backgroundColor,
        padding: { xs: '0.25rem 1.5rem', lg: '0.25rem 4rem' },
        position: 'sticky',
        top: '0',
        zIndex: '999',
      }}
      id={blok?.anchor?.[0]?.anchor_id}
    >
      <Box component="ul" sx={{ marginLeft: '-16px', marginTop: '0', marginBottom: '0', paddingLeft: '0' }}>
        {blok?.items?.map((currentItem) => {
          return (
            <li key={currentItem?._uid} style={{ padding: '0', display: 'inline-block' }}>
              <StoryblokServerComponent
                blok={currentItem}
                rels={rels}
                backgroundColor={backgroundColor}
                size="sm"
                color={blok?.dark ? 'light' : 'neutral'}
                variant="plain"
              />
            </li>
          )
        })}
      </Box>
    </Box>
  )
}

export default SecondaryNavigation
